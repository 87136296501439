/* Eric Meyer CSS Reset v2.0 */

/* http://meyerweb.com/eric/tools/css/reset/
 *    v2.0 | 20110126
 *       License: none (public domain)
 *       */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Start Restaurant Manager CSS */

body {
	font-family: "Helvetica", Arial, sans-serif;
}

@media screen and (max-device-width: 640px) and (orientation: portrait) {
	body {
		font-size: 3.5vw;
	}
}

/* NavBar */

.nav-bar {
	height: 50px;
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	background-color: #eeeeee;
	opacity: 0.95;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.nav-bar-spacer {
	height: 50px;
}

@media screen and (max-device-width: 640px) and (orientation: portrait) {
	.nav-bar {
		height: 150px;
	}

	.nav-bar-spacer {
		height: 150px;
	}
}

/* RestaurantCard */

.restaurant-card {
	margin: 15px 15px 15px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	font-weight: bold;
	color: #555555;
	padding-bottom: 15px;
}

.restaurant-card h1 {
	color: #eeeeee;
	font-size: 1.8em;
	text-align: center;
	padding: 15px;
	background-color: #4747d1;
}

.rc-highlight-stat {
	font-size: 4em;
	text-align: center;
	display: block;
	margin-top: 50px;
}

.rc-stat {
	font-size: 2.5em;
	display: block;
	text-align: center;
}

.rc-stat-label {
	font-size: 0.8em;
	text-align: center;
	margin-top: 10px;
}

.rc-highlight-stat-label {
	font-size: 1.2em;
	text-align: center;
	margin-top: 10px;
}

.rc-stat-container {
	padding: 0px 15px;
	border-size: 2px;
	border-color: black;
}

.rc-stat-box {
	display: flex;
	flex: 1;
	justify-content: space-around;
	margin-top: 30px;
	padding: 15px;
}

@media screen and (max-device-width: 640px) and (orientation: portrait) {
	.restaurant-card {
		margin: 3vw 3vw 3vw;
	}

	.restaurant-card h1 {
		font-size: 2.2em;
		padding: 4vw;
	}

	.rc-highlight-stat {
		margin-top: 10vw;
	}

	.rc-stat-label {
		margin-top: 3vw;
	}

	.rc-highlight-stat-label {
		margin-top: 3vw;
	}
	
	.rc-stat-box {
		margin: 8vw 3vw 3vw;
	}
}
